import React from "react";

const Video = ({ title, videoId }) => {
  // Strip HTML tags
  const rawId = videoId.replace(/(<([^>]+)>)/gi, "");

  return (
    <li className="videolist__list-item">
      <div className="videolist__video">
        <div className="videolist__video-container">
          <iframe
            title="Video"
            src={`https://player.vimeo.com/video/${rawId}`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </div>
      <div className="videolist__video-title">{title}</div>
    </li>
  );
};

const VideoList = ({ postData }) => {
  const videos = postData.edges;

  return (
    <div className="videolist">
      <div className="videolist__container">
        <div className="videolist__title">
          <h2>Video</h2>
        </div>
        <ul className="videolist__list">
          {videos.map((video) => (
            <Video
              key={video.node.id}
              title={video.node.title}
              videoId={video.node.content}
            />
          ))}
        </ul>
        <div></div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </div>
  );
};

export default VideoList;
